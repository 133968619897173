import React from 'react'

const Credit = () => {
  return (
    <>
      <h2 className="headLineInfo">Info</h2>
      <hr />
      <p>
        This App is Made By{' '}
        <a
          href="https://www.canwebe.in"
          target="_blank"
          rel="noopener noreferrer"
          className="cwb"
        >
          CanWeBe
        </a>
        <br />
        For any bugs reports or suggestion :{' '}
        <a
          href="https://www.canwebe.in/form/contact?refer=https://silentshare.netlify.app"
          target="_blank"
          rel="noopener noreferrer"
          className="contactUs"
        >
          Contact Us
        </a>
      </p>
      <h2 className="headLineCredit">Credits</h2>
      <div className="creditsA">
        <a href="https://www.flaticon.com/" rel="noreferrer" target="_blank">
          QR Icons by FlatIcon
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://icons8.com/icon/82709/upload"
        >
          Upload icon by Icons8
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://icons8.com/icon/86327/download"
        >
          Download icon by Icons8
        </a>
      </div>

      <h3>
        CanWeBe © {new Date().getFullYear()} All rights are Resrved and no one
        may reproduce, distribute, or create derivative works from SilentShare
      </h3>
    </>
  )
}

export default Credit
