import { motion } from 'framer-motion'
import React from 'react'
import './loader.css'

const loaderVariants = {
  animate1: {
    x: [-70, 70],
    y: [0, -85],
    transition: {
      x: { yoyo: Infinity, duration: 0.5 },
      y: { yoyo: Infinity, duration: 0.25, ease: 'easeOut' },
    },
  },
}
const Loader = () => {
  return (
    <div className='loaderContainer'>
      <motion.div
        className='loader'
        variants={loaderVariants}
        animate='animate1'
      ></motion.div>
    </div>
  )
}

export default Loader
