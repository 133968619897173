import { AnimatePresence } from 'framer-motion'
import React, { lazy, Suspense } from 'react'
import { Toaster } from 'react-hot-toast'
import { Route, Switch, useLocation } from 'react-router-dom'
import Loader from './components/loader/Loader'
import Nav from './components/Nav/Nav'
const Print = lazy(() => import('./pages/Print/Print'))
const App = lazy(() => import('./pages/App/App'))
const Downloader = lazy(() => import('./pages/Downloader/Downloader'))
const Generate = lazy(() => import('./pages/Generate/Generate'))
const Scan = lazy(() => import('./pages/Scan/Scan'))
const Uploader = lazy(() => import('./pages/Uploader/Uploader'))

const Routes = () => {
  const location = useLocation()

  return (
    <>
      <Nav />
      <Suspense fallback={<Loader />}>
        <AnimatePresence exitBeforeEnter>
          <Switch location={location} key={location.key}>
            <Route exact path="/" component={App} />
            <Route exact path="/send" component={Scan} />
            <Route exact path="/receive/:uuid" component={Generate} />
            <Route exact path="/files/:uuid" component={Uploader} />
            <Route exact path="/download/:uuid" component={Downloader} />
            <Route exact path="/print" component={Print} />
          </Switch>
        </AnimatePresence>
      </Suspense>
      <Toaster
        toastOptions={{
          style: {
            fontSize: '17px',
          },
        }}
      />
    </>
  )
}

export default Routes
