import React, { useState } from 'react'
import './Nav.css'
import { MdHelp, MdInfoOutline, MdNavigateBefore } from 'react-icons/md'
import { Link, withRouter } from 'react-router-dom'

import { AnimatePresence, motion } from 'framer-motion'
import Steps from '../Steps/Steps'
import Credit from '../Credit/Credit'
import Modal from '../Modal/Modal'

const svgVariants = {
  hidden: { rotate: 90 },
  visible: {
    rotate: 0,
    transition: { duration: 1, delay: 0.7 },
  },
}
const pathVariants = {
  hidden: { opacity: 0, pathLength: 0 },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: { duration: 2.5, ease: 'easeInOut' },
  },
}

const Nav = ({ location, history }) => {
  const [isModal, setIsModal] = useState('')

  return (
    <>
      <nav>
        <div className='wrapper navFlex'>
          <span onClick={() => history.goBack()} className='navSpan'>
            {location.pathname === '/print' && (
              <span>
                <MdNavigateBefore />
                Go Back
              </span>
            )}
          </span>
          <div className='logo'>
            <motion.svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 74.02 75.58'
              animate='visible'
              initial='hidden'
              style={{ originX: '15px', originY: '15px' }}
              variants={svgVariants}
            >
              <g id='Layer_2' data-name='Layer 2'>
                <g id='Layer_1-2' data-name='Layer 1'>
                  <motion.path
                    className='cls-1'
                    d='M70.02,4 C70.02,26.52667 70.02,49.05333 70.02,71.58 48.01333,71.58 26.00667,71.58 4,71.58 4,49.05333 4,26.52667 4,4 26.00667,4 48.01333,4 70.02,4 z'
                    variants={pathVariants}
                  ></motion.path>
                  <motion.path
                    className='cls-2'
                    d='M7.98,48.55 C21.15,48.55 34.32,48.55 47.49,48.55 '
                    variants={pathVariants}
                  ></motion.path>
                  <motion.path
                    className='cls-3'
                    d='M66.02,26.95 C52.83667,26.96667 39.65333,26.98333 26.47,27 '
                    variants={pathVariants}
                  ></motion.path>
                  <motion.path
                    className='cls-4'
                    d='M58.91,62.59 C58.91,57.91 58.91,53.23 58.91,48.55 '
                    variants={pathVariants}
                  ></motion.path>
                  <motion.path
                    className='cls-5'
                    d='M61.1,60.4 C56.56333,60.4 52.02667,60.4 47.49,60.4 '
                    variants={pathVariants}
                  ></motion.path>
                  <motion.path
                    className='cls-4'
                    d='M15.04,12.93 C15.04,17.61333 15.04,22.29667 15.04,26.98 '
                    variants={pathVariants}
                  ></motion.path>
                  <motion.path
                    className='cls-5'
                    d='M12.85,15.12 C17.39,15.12 21.93,15.12 26.47,15.12 '
                    variants={pathVariants}
                  ></motion.path>
                </g>
              </g>
            </motion.svg>

            <Link to='/'>SilentShare</Link>
          </div>
          <div>
            <span className='helpIcon' onClick={() => setIsModal('steps')}>
              <MdHelp />
            </span>
            <span
              className='helpIcon outline'
              onClick={() => setIsModal('credit')}
            >
              <MdInfoOutline />
            </span>
          </div>
        </div>
      </nav>
      <AnimatePresence>
        {isModal === 'steps' && (
          <Modal setIsModal={setIsModal} key='steps'>
            <Steps />
          </Modal>
        )}
        {isModal === 'credit' && (
          <Modal setIsModal={setIsModal} key='credit' small>
            <Credit />
          </Modal>
        )}
      </AnimatePresence>
    </>
  )
}

export default withRouter(Nav)
