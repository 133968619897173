import { motion } from 'framer-motion'
import React from 'react'
import './Modal.css'
import { MdCancel } from 'react-icons/md'

const backdropVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exit: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
    },
  },
}

const cardVariants = {
  hidden: { y: '-100vh' },
  visible: {
    y: 0,
    transition: { type: 'spring', stiffness: 120, damping: 13, delay: 0.4 },
  },
  exit: {
    scale: 0,
    opacity: 0,
    transition: {
      ease: 'easeInOut',
      duration: 0.3,
    },
  },
}
const Modal = ({ setIsModal, children, small }) => {
  const handleClick = (e) => {
    if (e.target.classList.contains('backDrop')) {
      setIsModal('')
    }
  }
  return (
    <motion.div
      onClick={handleClick}
      className='backDrop'
      variants={backdropVariants}
      initial='hidden'
      animate='visible'
      exit='exit'
    >
      <motion.div
        className={`modalCard ${small ? 'small' : ''}`}
        variants={cardVariants}
      >
        <span className='cancelIcon' onClick={() => setIsModal('')}>
          <MdCancel />
        </span>
        {children}
      </motion.div>
    </motion.div>
  )
}

export default Modal
