import React from 'react'

const Steps = () => {
  return (
    <>
      <h2 className="headlineCentre">
        This Application Maintains Confidentiality Of Devices,Users and User
        Files/Folders
      </h2>
      <p className="headtitle">
        Open <span className="weblink">silentshare.netlify.app</span> Web App on
        Both the Sender and Receiver Devices
      </p>
      <h2 className="device">Sender</h2>
      <ul>
        <li>Scan the Receivers QR Code or use the Url</li>
        <li>Upload your desired file/folder using the Upload Button</li>
        <li>
          Once the process is complete <strong>Close the Connection </strong>
          using Close Connection Button <br />
          <span className="notifiCation">
            !! This will terminate the connection between you and the receiver
            and erase your files/folder from the server. !!
          </span>
        </li>
      </ul>
      <h2 className="device">Receiver</h2>
      <ul>
        <li>Press Get Your files button </li>
        <li>
          Download or Print the files/folders uploaded by the sender as per
          choice
        </li>
      </ul>
    </>
  )
}

export default Steps
